<template>

  <div v-if="isAdmin" class="container">
    <h1 class="text-danger"><i class="fa fa-wrench"></i> Admin Page</h1>
    <hr>
    <img style="margin-right: 5px;" :src="winIcon" alt="won" width="16" height="16">
    <router-link to="/admin/users">users</router-link>
    <br><br><br><br>
    <button class="btn btn-sm btn-outline-primary" type="button" @click="testApiCall">
      <i class="fa fa-paper-plane"></i> test api
    </button>
  </div>

</template>


<script>
//import axios from "axios";
import {userStore} from "@/userStore";
import axios from "axios";

export default {
  name: "AdminPage",
  components: {},
  props: [],
  data() {
    return {
    }
  },
  computed: {
    isAdmin() {
      return userStore.isAdmin();
    },
    winIcon() {
      return `${process.env.BASE_URL}win_icon-bw.svg`
    }
  },
  methods: {

    async testApiCall() {

      axios.get('api/film/genres')
      //axios.post(`/api/admin/send-test-email/arods.island@gmail.com`)
          .then((response) => {
            console.log(response);
          })
          .catch((err) => {
            console.log(err);
          });
    },
  }
}
</script>


<style scoped>

</style>