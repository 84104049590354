import { reactive } from 'vue'

export const userStore = reactive({
    user: null,
    isCeremonyReviewed(_ceremony) {
        return this.user?.ceremonies_reviewed?.includes(_ceremony);
    },
    isFilmMarkedViewed(_film_id) {
        return this.user?.viewed?.includes(_film_id);
    },
    isFilmBookmarked(_film_id) {
        return this.user?.watchlist?.includes(_film_id);
    },
    isFilmBookmarkedAndUnviewed(_film_id) {
        return this.isFilmBookmarked(_film_id) && !this.isFilmMarkedViewed(_film_id);
    },
    isLoggedIn() {
        return (this.user !== null);
    },
    isAdmin() {
      return (this.isLoggedIn() && this.user.isAdmin);
    },
    isTester() {
        return (this.isLoggedIn() && this.user.isTester);
    },
    getFriendConnectionId() {
      return this.user?._id;
    },
    search: {
        text: '',
        type: ''
    }
});

export const searchStore = reactive({
    text: '',
    type: ''
});