<template>
  <div class="card text-bg-light">
    <div :class="`card-body ${showExpanded? 'expanded' : 'not-expanded'}`">
      <slot></slot>
      <span v-show="filtersApplied" class="filters-applied-note" >
        <i class="fa fa-filter"></i> filters applied
      </span>

      <div v-if="showExpanded" class="btn btn-sm btn-show-more" type="button" @click="showExpanded = !showExpanded">
        <i :class="showExpanded? 'fa fa-caret-up' : 'fa fa-caret-down'" aria-hidden="true"></i>
        <span v-text="showExpanded? ' show less ' : ' show more '" aria-hidden="true"></span>
      </div>
      <hr>
      <LoadingSpinner v-if="waitingForResponse" class="text-secondary" style="margin-top: 10px;"></LoadingSpinner>
      <div v-else v-for="(day) in displaySchedule.daysToDisplay(hideViewedFilms, filterByGenres)" :key="day.titleString()">
        <div class="schedule-date">{{day.titleString()}}</div>
        <div v-for="(program) in day.programsToDisplay(hideViewedFilms, filterByGenres)" :key="program.film_id" class="container">
          <span class="schedule-time">{{program.timeString()}}</span>
          <FilmLink :film_name="program.filmName()" :film_id="program.film_id" :show_if_bookmarked="true"></FilmLink>
          <LanguageIcon class="language-icon" v-if="program.film.original_language !== 'en'" :language_code="program.film.original_language" :height="5" :superscript="true"></LanguageIcon>
        </div>
      </div>

    </div>

    <div class="card-footer">
      <div class="btn btn-sm btn-show-more" type="button" @click="showExpanded = !showExpanded">
        <i :class="showExpanded? 'fa fa-caret-up' : 'fa fa-caret-down'" aria-hidden="true"></i>
        <span v-text="showExpanded? ' show less ' : ' show more '" aria-hidden="true"></span>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import {Film} from "@/film";
import FilmLink from "@/components/FilmLink.vue";
import {userStore} from "@/userStore";
import LanguageIcon from "@/components/LanguageIcon.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

// A scheduled program
class Program {
  constructor(_scheduledProgram) {
    this.start_time = new Date(_scheduledProgram.start_time);
    this.film_id = _scheduledProgram.film_id;
    this.film = null;
  }

  isMarkedViewed() {
    return userStore.isFilmMarkedViewed(this.film_id);
  }

  matchesAnyGenreInList(_genreList) {
    const foundGenre = !!this.film?.tmdb?.genres?.find((programGenre) => {
      return _genreList.includes(programGenre);
    });

    return foundGenre;
  }

  getGenres() {
    if ( !this.film ) {
      return [];
    }
    return ;
  }

  aquireFilmData(_filmList) {
    if ( !this.film ) {
      this.film = _filmList.find((_film) => {
        return (this.film_id === _film._id);
      });
    }
  }

  timeString() {
    return this.start_time.toLocaleTimeString().replace(':00 AM', 'am').replace(':00 PM', 'pm');
  }

  filmName() {
    return this.film?.name ? this.film?.name : this.film_id;
  }

  static compareChronologically(_prog1, _prog2) {
    return (
        (_prog1.start_time < _prog2.start_time)? -1
            : (_prog1.start_time < _prog2.start_time)? 1
                : 0
    );
  }
}

// A day of scheduled programs
class Day {
  constructor(_date) {
    if (_date instanceof Date) {
      this.day = _date.getDay();
      this.date = _date.getDate();
      this.month = _date.getMonth();
      this.year = _date.getFullYear();
    } else {
      this.day = 0;
      this.date = 0;
      this.month = 0;
      this.year = 0;
    }
    this.programs = [];
  }

  matchesDate(_date) {
    return (_date instanceof Date)
        && (_date.getDate() === this.date)
        && (_date.getMonth() === this.month)
        && (_date.getFullYear() === this.year);
  }

  canAddProgram(_program) {
    return this.matchesDate(_program.start_time);
  }

  addProgram(_program) {
    if ( !this.canAddProgram((_program)) ) {
      throw Error("Trying to add a Program to a Day that can't be added to that day");
    }
    this.programs.push(_program);
    this.programs.sort(Program.compareChronologically)
  }

  populateWithFilmData(_filmList) {
    this.programs.forEach((_program) => {
      _program.aquireFilmData(_filmList);
    });
  }

  programsToDisplay(_hideViewedFilms, _filterByGenres) {
    return this.programs.filter((program) => {

      // hide viewed films if requested
      if ( _hideViewedFilms && program.isMarkedViewed() ) {
        return false;
      }

      // hide films that don't match any of the filtered genres
      if ( _filterByGenres && (_filterByGenres.length > 0) && !program.matchesAnyGenreInList(_filterByGenres) ) {
        return false;
      }

      // hide films in the past
      return (program.start_time > Date.now());
    });
  }

  titleString() {
    const kToday = new Date;
    const kTomorrow = new Date(kToday.getTime() + 24 * 60 * 60 * 1000);

    return this.matchesDate(kToday)? 'today'
        : this.matchesDate(kTomorrow) ? 'tomorrow'
            : Day.titleString(this.day, this.date, this.month);
  }

  static titleString(_day, _date, _month /*,_year*/) {
    const kDayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const kMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${kDayNames[_day]}, ${kMonthNames[_month]} ${_date}`;
  }

  static compareChronologically(_day1, _day2) {

    return (
        ( _day1.year !== _day2.year )? ( _day1.year - _day2.year )
            : ( _day1.month !== _day2.month ) ? ( _day1.month - _day2.month )
                : ( _day1.date !== _day2.date ) ? ( _day1.date - _day2.date )
                    : 0
    );
  }
}

// A collection of days of scheduled programs.
class Schedule {
  constructor() {
    this.days = [];
  }

  async populate(_provider) {

    // get the schedule for this provider
    return axios.get(`/api/viewing-options/scheduled/${_provider}`)
        .then((response) => {
          //console.log(`/api/viewing-options/${this.provider} => ${response}`);
          let responseSchedule = response?.data?.schedule;
          if ( !responseSchedule ) {
            throw Error(`Invalid response from /api/viewing-options/${_provider}`);
          }
          responseSchedule.forEach((scheduledProgram) => {
            this.addProgram(new Program(scheduledProgram));
          });
          return axios.post('/api/film',{ film_ids: responseSchedule.map( program => program.film_id ) } );
        })
        .then((response) => {
          const responseFilmList = response.data.map(_film => new Film(_film));
          this.populateWithFilmData(responseFilmList);
        });
  }

  addProgram(_program) {
    // find or add day
    let dayAdded = false;
    let dayIndex = this.days.findIndex((day) => {
      return day.canAddProgram(_program);
    });
    if ( dayIndex === -1 ) {
      this.days.push(new Day(_program.start_time));
      dayAdded = true;
      dayIndex = (this.days.length - 1);
    }

    // add the program and sort if a new day was added
    this.days[dayIndex].addProgram(_program);
    if ( dayAdded ) {
      this.days.sort(Day.compareChronologically);
    }
  }

  populateWithFilmData(_filmList) {
    this.days.forEach((_day) => {
      _day.populateWithFilmData(_filmList);
    })
  }

  daysToDisplay(_hideViewedFilms, _filterByGenres) {
    return this.days.filter((day) => {
      return (day.programsToDisplay(_hideViewedFilms, _filterByGenres).length > 0);
    });
  }
}

export default {
  name: "ScheduledFilmListCard",
  components: {LoadingSpinner, LanguageIcon, FilmLink},
  props: ['title', 'provider', 'hideViewedFilms', 'filterByGenres'],
  computed: {
    filtersApplied() {
      return ( this.hideViewedFilms || ( this.filterByGenres && this.filterByGenres.length > 0 ) );
    }
  },
  data() {
    return {
      waitingForResponse: false,
      displaySchedule: new Schedule(),
      showExpanded: false
    }
  },

  async created() {

    this.waitingForResponse = true;
    this.displaySchedule.populate(this.provider)
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.waitingForResponse = false;
      });
  },

  methods: {
  }
}

</script>


<style scoped>

  h6 {
    padding-top: 10px;
  }

  .expanded {
    max-height: auto;
  }

  .not-expanded {
    max-height: 200px;
  }

  .card {
    margin-bottom: 20px;
  }

  .card-body {
    font-size: x-small;
    overflow-y: hidden;
  }

  .card-footer {
    background-color: var(--bs-light);
    border-top: none;
  }

  .btn-show-more {
    float: right;
    font-size: small;
    color: var(--bs-secondary);
    opacity: 80%;
  }

  .schedule-date {
    font-size: small;
    font-weight: 700;
    color: var(--bs-secondary);
    padding-top: 5px;
    padding-bottom: 2px;
  }

  .schedule-time {
    display: inline-block;
    width: 50px;
  }

  .filters-applied-note {
    float: right;
    color: var(--bs-secondary);
    opacity: 80%;
    font-size: 9px;
    margin-top: -4px;
  }

  .language-icon {
    padding-left: 0.2em;
    opacity: 80%;
  }

</style>